import React from 'react';
import "animate.css/animate.min.css"
import SBDStyle from './SBoardDescription.module.css'
import Cover from '../assets/Regulator_panel.png'
import SBoardRelatedCards from "./SBoardRelatedCards";
import SB_Footer from "./SB_Description_Footer";

class SBADSRDescription extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className={SBDStyle.container}>
                <div style={{color: '#0f0f0f', background: '#f0f0f0'}}>
                    <div className={SBDStyle.titleContainer} style={{color: '#0f0f0f'}}>
                        <h2>Regulator</h2>
                        <h3>100% control on your CV | Made for S-Board</h3>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <img src={Cover} className={SBDStyle.banner} style={{maxHeight: '372px', width: 'auto'}}/>
                        <div style={{height: "16px"}}/>
                    </div>
                    <div style={{textAlign:"center", background: '#f0f0f0'}}>
                        <p className={SBDStyle.slogan}>CV processing utility</p>
                        <div style={{height: "30px"}}/>
                    </div>
                    <div className={SBDStyle.cartArea}>
                        <div className={SBDStyle.cartAreaContent}>
                            <div>
                                <p style={{color: '#6f747f', fontWeight: 800}}>Modifier</p>
                                <p style={{fontSize: '1.8rem', textTransform: 'uppercase', fontWeight: 800}}>Regulator</p>
                                <div className={SBDStyle.currPrice}>US $19</div>
                            </div>          
                            <div className={SBDStyle.buttonWrapper} style={{textAlign:'center'}}>
                                <p style={{paddingTop: '0.6rem'}}>Must use with S-Board!</p>
                                <button onClick={this.props.addToCart} className={SBDStyle.addToCartButton}>Add To Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={SBDStyle.titleContainer}>
                        <h2>Overview</h2>
                    </div>
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>All rounder CV regulator</h4>
                        <p>Dim the voltage, remap the range, invert the phase, quantize the signal. It gets your CV 100% under control.</p>
                    </div>
                    <div style={{height: "60px"}}/>  
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>Boost beyond the range</h4>
                        <p>S-Board's CV signal amplitude typically ranges between -1 and 1, sometimes you'll find a CV in full amplitude is still not strong enough to drive a knob in full range. Regulator allows you to boost the CV up to 200%, this gives you enough juice to drive any knobs in full range.</p>
                    </div>
                    <div style={{height: "60px"}}/>  
                    <div className={SBDStyle.content} style={{margin: 'auto'}}>
                        <h4>Intuitive visualization</h4>
                        <p>The waveform monitor gives you an intuitive visualization, makes it easy for you to see what's going on, and how to tweak the parameters.</p>
                    </div>
                </div>
                <SBoardRelatedCards currentItem="regulator"/>

                <SB_Footer getTrial={this.props.getTrial}/>
            </div>   
        )
    }
}

export default SBADSRDescription